$(document).ready(function () {
	if ($.fn.modal) {
		$('.modal').modal({
			show: false
		}).on('shown.bs.modal', function () {
			var video = this.querySelector('video');
			if (video != null) {
				video.currentTime = 0;
				video.play();
			}
		}).on('hidden.bs.modal', function () {
			var video = this.querySelector('video');
			if (video != null) {
				video.pause();
				video.currentTime = 0;
			}
		});
	}

	$('video[data-video-sources]').each(function () {
		var videoSourcesRaw = this.getAttribute('data-video-sources');
		var videoSources = JSON.parse(videoSourcesRaw);
		var video = this;
		var cleanup = false;

		if (videoSources.hlsLink && Hls.isSupported()) {
			var config = {
				startLevel: 3,
				initialLiveManifestSize: 3
			};
			var hls = new Hls(config);
			hls.loadSource(videoSources.hlsLink);
			hls.attachMedia(video);
			hls.on(Hls.Events.MANIFEST_PARSED, onHlsEvent);
			hls.on(Hls.Events.MEDIA_ATTACHED, onHlsEvent);
			hls.on(Hls.Events.BUFFER_APPENDED, onHlsEvent);

			cleanup = true;
		} else if (videoSources.hlsLink && video.canPlayType('application/vnd.apple.mpegurl')) {
			video.src = videoSources.hlsLink;
			video.addEventListener('loadedmetadata', onCanPlay);

			cleanup = true;
		} else if (videoSources.mp4LinkSD && video.canPlayType('video/mp4')) {
			video.src = videoSources.mp4LinkSD;
			video.type = 'video/mp4';

			cleanup = true;
		}

		if (cleanup) {
			$(video).closest('.video-player').css('background', 'none');

			video.addEventListener('canplaythrough', onCanPlay, false);
			video.addEventListener('load', onCanPlay, false);

			video.addEventListener('pause', videoUpdateListenerDebounced);
			video.addEventListener('ended', videoUpdateListenerDebounced);
			video.addEventListener('timeupdate', videoUpdateListenerDebounced);
		}

		function onHlsEvent(e) {
			if (e == 'hlsMediaAttached') {
				hls.off(Hls.Events.MEDIA_ATTACHED, onHlsEvent);
			}
			if (e == 'hlsManifestParsed') {
				hls.off(Hls.Events.MANIFEST_PARSED, onHlsEvent);
			}

			if (e == 'hlsBufferAppended') {
				hls.off(Hls.Events.BUFFER_APPENDED, onHlsEvent);
			}
		}

		function onCanPlay(e) {
			if (e.type == 'canplaythrough') {
				video.removeEventListener('canplaythrough', onCanPlay, false);
			} else if (e.type == 'load') {
				video.removeEventListener('load', onCanPlay, false);
			} else if (e.type == 'loadedmetadata') {
				video.removeEventListener('loadedmetadata', onCanPlay, false);
			}
		}
	});
});

var videoUpdateListenerDebounced = debounce(videoUpdateListener, 250);
function videoUpdateListener(e) {
	var video = e.srcElement;

	if (video.currentTime >= video.duration && $.fn.modal) {
		$(video).closest('.modal').modal('hide');
	}
}

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
